.containerUser {
  width: 100%;
  margin: 0 auto;
  background-color: #f1f2f7;
  height: 150x;

}
.containerUser1 {
  width: 100%;
  margin: 0 auto;
  background-color: #f1f2f7;
  height: 50x;

}


.buttonStyle {
  background-color: #fcfcfc;
  color: #154360;
  border: none;
  border-radius: 5px;
  padding: 0px 15px;
  cursor: pointer;
  margin: 10px 0px;
  margin-right: 10px;
  /* font-family: "Poppins"; */
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 44px;
  border: 1px solid #154360;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  
}
.buttonActive {
  background-color: #154360 !important;
  color: white !important;
}

.headingUser {
  /* font-family: "Poppins"; */
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 44px;
  color: #154360;
  position: relative;
  right: 60px;
  bottom: 19px
}
.heading_manu{
position: relative;
top:50px;
right: 400px;
}

.customInputNumber::-webkit-outer-spin-button,
.customInputNumber::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.customInputNumber[type="number"] {
  -moz-appearance: textfield;
}

.modalBackgroundShadow {
  display: block!important;
  position: fixed!important;
  width: 100vw!important;
  height: 100vh!important;
  background-color: #fff!important;
}
.formConfirmOtp {
  position: relative;
}
.formControlOtpError{
  position: absolute;
  right: 20px;
  color:red;

}
.resendOTPBtn{
  display: block;
  margin-top: 1vh;
}

.react-datepicker__input-container input{
height: 58px !important;
border: 1px solid lightgray !important;
border-radius: 3px !important;
padding: 15px !important;
direction: ltr;
width: 100%;
}
.react-datepicker-wrapper{
    width: 100% !important;
}
.react-datepicker__input-container input {
    height: 58px;
    border: 1px solid lightgray;
    border-radius: 3px;
    padding: 15px;
    direction: ltr;
    text-align: left; /* Ensures input text is left-aligned */
}

.react-datepicker__input-container input::placeholder {
    text-align: right; /* Aligns placeholder text to the right */
    /* Optional: Add other placeholder styles as needed */
    /* direction: rtl; */ /* Optional: Remove if not needed */
}

/* Vendor prefixes for broader support */
.react-datepicker__input-container input::-webkit-input-placeholder {
    text-align: right;
}

.react-datepicker__input-container input::-moz-placeholder {
    text-align: right;
}

.react-datepicker__input-container input:-ms-input-placeholder {
    text-align: right;
}

.react-datepicker__input-container input::-ms-input-placeholder {
    text-align: right;
}

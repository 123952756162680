.tableRow {
  vertical-align: top;
}

.imagesContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}
.imageWrapper {
  position: relative;
}
.imageWrapper:not(:last-child) {
  margin-right: 10px;
}
.productImage {
  width: 100px;
  object-fit: cover;
}
.removeWrapper {
  position: absolute;
  cursor: pointer;
  left: 4px;
  top: 4px;
  background-color: #fff;
}
.imagesHolder{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  row-gap: 10px;
}
.removeItem{
  /* margin-bottom: 10px; */
}

.middleTd{
  width: 150px;
}

.wideTd{
  width: 200px;
}

.blockTitle{
  width: 20vw;
  flex: 0 0 20vw;
}

.description{
  width: 100%;
}

.descTitle{
  min-width: 150px;
}
.descTextArea{
  min-width: 250px;
}
.removeItemDesc{
  margin-top: 32px;
}

.modalBackgroundShadow {
  display: block!important;
  position: fixed!important;
  width: 100vw!important;
  height: 100vh!important;
}
.img-no-people {
  display: none;
}

.no-people-page .img-no-people {
  display: block;
}
.no-people-page .img-people {
  display: none;
}
.body{
  background-color: aqua;
}
.imagesContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}
.imageWrapper {
  position: relative;
}
.imageWrapper:not(:last-child) {
  /* margin-right: 10px; */
  display: inline-block;
  margin-bottom: 10px;
}
.productImage {
  width: 100px;
  object-fit: cover;
}

.controlBtns{
  display: flex;
  flex-direction: column;
  gap: 10px;
}
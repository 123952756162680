.containerUser {
  width: 90%;
  margin: 0 auto;
  margin-top: 2rem;
}
.headingUser {
  /* font-family: "Poppins"; */
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 44px;
  color: #154360;
}
.table {
  width: 100%;
}

.table thead th {
  background-color: #f8f9fa;
  font-weight: bold;
  writing-mode: vertical-rl;
  transform: rotate(180deg);
}

.tableStriped tbody tr:nth-of-type(even) {
  background-color: #f8f9fa;
}

.table td,
.table th {
  padding: 0.75rem;
  min-width: 100px;
  word-wrap: break-word;
  text-align: center;
}

.tableResponsive {
  overflow-x: auto;
}

.horizantal {
  background-color: #f8f9fa;
  font-weight: bold;
  writing-mode: horizontal-tb !important;
  transform: rotate(360deg) !important;
}

.multiSelectDropdown {
  width: 100%;
  height: 36px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  background-color: #fff;
  color: #333;
  outline: none;
}

.multiSelectDropdown option {
  padding: 4px 8px;
}

.multiSelectDropdown option:checked {
  background-color: #007bff;
  color: #fff;
}

.btnStyle {
  background-color: #154360;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  /* font-family: "Poppins"; */
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  border: 1px solid #154360;
}

@media (max-width: 575.98px) {
  .table thead th {
    display: none;
  }

  .table td,
  .table th {
    white-space: normal;
    display: block;
    text-align: left;
  }

  .table td:before,
  .table th:before {
    content: attr(data-label);
    font-weight: bold;
    margin-bottom: 0.5rem;
  }

  .table tr {
    display: block;
    margin-bottom: 1rem;
    border-bottom: 1px solid #dee2e6;
  }
}

.containerUser {
  width: 90%;
  margin: 0 auto;
  margin-top: 2rem;
}
.filterBox {
  margin: 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.headingUser {
  /* font-family: "Poppins"; */
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 44px;
  color: #154360;
}
.switch {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 24px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  left: 2px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.switchLabel {
  /* font-family: "Poppins"; */
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  margin-left: 5px;
}
.buttonStyle {
  background-color: #fcfcfc;
  color: #154360;
  border: none;
  border-radius: 5px;
  padding: 0px 15px;
  cursor: pointer;
  margin: 10px 0px;
  margin-right: 10px;
  /* font-family: "Poppins"; */
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 44px;
  border: 1px solid #154360;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}
.buttonActive {
  background-color: #154360 !important;
  color: white !important;
}

.containerUser {
  width: 90%;
  margin: 0 auto;
  margin-top: 2rem;
}
.headingUser {
  /* font-family: "Poppins"; */
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 44px;
  color: #154360;
}
.cardHeight {
  height: 40vh;
}
.buttonStyle {
  background-color: #fcfcfc;
  color: #154360;
  border: none;
  border-radius: 5px;
  padding: 0px 15px;
  cursor: pointer;
  margin: 10px 0px;
  margin-right: 10px;
  /* font-family: "Poppins"; */
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 44px;
  border: 1px solid #154360;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}
.buttonActive {
  background-color: #154360 !important;
  color: white !important;
}
.uploadContainer {
  width: 100%;
}
.fileInput {
  width: 100%;
}
.error {
  color: red;
}
.success {
  color: #6cba7d;
}
.btnStyle {
  background-color: #154360;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  margin: 10px 0px;
  margin-right: 10px;
  /* font-family: "Poppins"; */
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  border: 1px solid #154360;
}
.opacity {
  opacity: 0.5;
}

.table {
  width: 100%;
  background-color: #f1f2f7;
  

}


.containerUser{
  max-width: 100%;
  margin: 0 auto;

}

.table thead th,
.table .thead .th {
  background-color: #6c757d!important;
  font-weight: bold;
 /* writing-mode: vertical-rl;
  transform: rotate(180deg);*/
  color: white;
}




.tableStriped tbody tr:nth-of-type(even),
.tableStriped .tbody .tr:nth-of-type(even)
 {
  background-color: #dee2e6;
}

.table td,
.table th,
.table .td,
.table .th
 {
  padding: 0.75rem;
  min-width: 100px;
  word-wrap: break-word;
  text-align: center;

}

.tableResponsive {
  overflow-x: auto;
  margin-bottom: 25px;
}

.horizantal{
  background-color: #f8f9fa;
  font-weight: bold;
  writing-mode: horizontal-tb !important;
  transform: rotate(360deg) !important;
}

.switch {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 24px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  left: 2px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.switchLabel {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  margin-left: 5px;
}

.actionbutton{
  margin: 10px !important;
    width: 85px !important;
}

@media (max-width: 575.98px) {
  .table thead th {
    display: none;
  }

  .table td,
  .table th {
    white-space: normal;
    display: block;
    text-align: left;
  }

  .table td:before,
  .table th:before {
    content: attr(data-label);
    font-weight: bold;
    margin-bottom: 0.5rem;
  }

  .table tr {
    display: block;
    margin-bottom: 1rem;
    border-bottom: 1px solid #dee2e6;
  }
}


.tableProducImage {
  max-width: 140px;
}
.tableUserCredentials{
  text-align-last: center;
  font-size: 14px;
}
.tableUserCredentialsRow{
  padding-left: 15px;
}